import React from "react";
import { Route } from "react-router-dom";
import { apiDocsPlugin, ApiExplorerPage } from "@backstage/plugin-api-docs";
import { CatalogEntityPage, CatalogIndexPage, catalogPlugin } from "@backstage/plugin-catalog";
import { CatalogImportPage, catalogImportPlugin } from "@backstage/plugin-catalog-import";
import { ExplorePage } from "@backstage-community/plugin-explore";

import { ScaffolderFieldExtensions } from "@backstage/plugin-scaffolder-react";
import { explorePage } from "./components/explore/ExplorePage";
import { ScaffolderPage, scaffolderPlugin } from "@backstage/plugin-scaffolder";
import { orgPlugin } from "@backstage/plugin-org";
import { SearchPage } from "@backstage/plugin-search";
import { TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage } from "@backstage/plugin-techdocs";
import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";

import {  UserSettingsPage } from "@backstage/plugin-user-settings";
import { apis } from "./apis";
import { entityPage } from "./components/catalog/EntityPage";
import { searchPage } from "./components/search/SearchPage";
import { Root } from "./components/Root";
import { ChannelPicker, SystemPickerField, DomainSystemPickerField } from "@internal/plugin-devhub-deployments";
import { AlertDisplay, OAuthRequestDialog, SignInProviderConfig, SignInPage } from "@backstage/core-components";
import { createApp } from "@backstage/app-defaults";
import { githubAuthApiRef, IdentityApi } from "@backstage/core-plugin-api";
import { AppRouter, FlatRoutes } from "@backstage/core-app-api";
import { CatalogGraphPage } from "@backstage/plugin-catalog-graph";
import { RequirePermission } from "@backstage/plugin-permission-react";
import { githubDeploymentsPlugin } from "@backstage-community/plugin-github-deployments";
import { catalogEntityCreatePermission } from "@backstage/plugin-catalog-common/alpha";
//import { discoveryApiRef, useApi } from "@backstage/core-plugin-api";

import { AuthProvider } from "./context/auth";

import "./assets/spezia-font-face.css";
import { themes } from "./themes/themes";
//import { HomepageCompositionRoot } from "@backstage/plugin-home";
import { HomePage } from "./components/home/HomePage";
import { CloudVPCRegionPickerField } from "@internal/plugin-devhub-deployments/src/ui-fields";

import { DevToolsPage } from "@backstage/plugin-devtools";
import { customDevToolsPage } from "./components/devtools/CustomDevToolsPage";
import { catalogUnprocessedEntitiesPlugin } from "@backstage/plugin-catalog-unprocessed-entities";
import { devToolsAdministerPermission } from "@backstage/plugin-devtools-common";
import { routeAccessPermission } from "@internal/common-utils";
import { HomepageCompositionRoot } from "@backstage/plugin-home";
import { NoTeamErrorPage } from "./components/error/NoTeamErrorPage";
//import { setTokenCookie } from './cookieAuth';

// this is to setup loginpage in frontend, it has github login page
const githubProvider: SignInProviderConfig = {
  id: "github-auth-provider",
  title: "GitHub",
  message: "Sign in using GitHub",
  apiRef: githubAuthApiRef,
};

const app = createApp({
  apis,
  themes,
  components: {
    SignInPage: (props) =>
    {
      //const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          { ...props }
          providers={ [githubProvider] }
          title=""
          align="left"
          onSignInSuccess={ async (identityApi: IdentityApi) =>
          {
            //setTokenCookie(await discoveryApi.getBaseUrl("cookie"),identityApi,);
            props.onSignInSuccess(identityApi);
          } }
        />
      );
    },
  },
  plugins: [githubDeploymentsPlugin, catalogUnprocessedEntitiesPlugin],
  bindRoutes({ bind })
  {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    // bind(catalogGraphPlugin.externalRoutes, {
    //   catalogEntity: catalogPlugin.routes.catalogEntity,
    // });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    // bind(explorePlugin.externalRoutes, {
    //   catalogEntity: catalogPlugin.routes.catalogEntity,
    // });
  },
});

// setting default maxDepth to 2 (earlier set to infinity) to save rendering time on Catalog grpah
const catalogGraphInitialState = {
  maxDepth: 2,
};

const routes = (
  <FlatRoutes>
    {/* <Route path="/" element={<HomepageCompositionRoot />}>{HomePage}</Route> */ }

    <Route
      path="/"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <HomepageCompositionRoot />
        </RequirePermission>
      }
    >
      { HomePage }
    </Route>

    <Route
      path="/catalog"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <CatalogIndexPage initiallySelectedFilter="all" initiallySelectedNamespaces={ ['default'] } />
        </RequirePermission>
      }
    />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <CatalogEntityPage />
        </RequirePermission>
      }
    >
      { entityPage }
    </Route>

    <Route
      path="/docs"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <TechDocsIndexPage />
        </RequirePermission>
      }
    />

    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <TechDocsReaderPage />
        </RequirePermission>
      }
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>

    <Route
      path="/create"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <ScaffolderPage
            groups={ [
              {
                title: "Services",
                filter: (entity) => (entity?.metadata?.tags?.includes("java") || entity?.metadata?.tags?.includes("base")) ?? false,
              },
              {
                title: "Cerebro",
                filter: (entity) => entity?.metadata?.tags?.includes("cerebro") ?? false,
              },
              {
                title: "GitHub Management",
                filter: (entity) => entity?.metadata?.tags?.includes("github-mgnt") ?? false,
              },
              {
                title: "Add-on",
                filter: (entity) => entity?.metadata?.tags?.includes("add-on") ?? false,
              },
            ] }
          />
        </RequirePermission>
      }
    >
      <ScaffolderFieldExtensions>
        <ChannelPicker />
        <SystemPickerField />
        <DomainSystemPickerField />
        <CloudVPCRegionPickerField />
      </ScaffolderFieldExtensions>
    </Route>

    {/* <Route path="/api-docs" element={<ApiExplorerPage />} /> */ }

    <Route
      path="/api-docs"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <ApiExplorerPage />
        </RequirePermission>
      }
    />

    <Route
      path="/explore"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <ExplorePage />
        </RequirePermission>
      }
    >
      { explorePage }
    </Route>

    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={ catalogEntityCreatePermission }>
          <CatalogImportPage />
        </RequirePermission>
      }
    />

    {/* <Route path="/search" element={<SearchPage />}></Route> */ }
    <Route
      path="/search"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <SearchPage />
        </RequirePermission>
      }
    >
      { searchPage }
    </Route>

    <Route path="/settings" element={ <UserSettingsPage /> } />

    <Route
      path="/catalog-graph"
      element={
        <RequirePermission permission={ routeAccessPermission } errorPage={ <NoTeamErrorPage /> }>
          <CatalogGraphPage initialState={ catalogGraphInitialState } />
        </RequirePermission>
      }
    />

    <Route
      path="/devtools"
      element={
        <RequirePermission permission={ devToolsAdministerPermission }>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      { customDevToolsPage }
    </Route>
  </FlatRoutes>
);

export default app.createRoot(
  <AuthProvider>
    <>
      <AlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <Root>{ routes }</Root>
      </AppRouter>
    </>
  </AuthProvider>
);

// const App = () => (
//   <AuthProvider>
//   <AppProvider>
//     <AlertDisplay />
//     <OAuthRequestDialog />
//     <AppRouter>
//         <Root>{routes}</Root>
//     </AppRouter>
//   </AppProvider>
// </AuthProvider>
// );

// export default App;
